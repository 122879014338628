import { Grid, Box, Toolbar, Typography, IconButton, Stack, Tooltip, Avatar, Menu, MenuItem, Button, AppBar, Container } from "@mui/material"

import { Outlet, useNavigate } from "react-router";
import { useState } from "react";
import { getUserSession, setUserSession } from "../Utils/Common";
import MenuDrawer from "../home/MenuDrawer";
import BalanceView from "./fragement/BalanceView";
import MenuIcon from '@mui/icons-material/Menu';
import mechantConfig from "../app/mechant.config";
const HomeLayout = (props) => {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null);
    const navigate = useNavigate()
    const session = getUserSession()
    const path = window.location.pathname.split('/')
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(true);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleToggleDrawer = (open) => {
        setAnchorElNav(open);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleLogout = () => {
        handleCloseUserMenu()
        setUserSession()
        navigate('/user/signin')
    }
    const merchant = mechantConfig.getMerchant()
    const membermenus = session ? [
        { label: "Home", onClick: (e) => { window.location.href = merchant?.home } },
        merchant?.online_shop && { label: "Shop", to: "/member/shop" },
        { label: "Events", to: "/member/events" },
        { label: "Schedule", to: "/member/schedule" },
        { label: "Transactions", to: "/member/transactions" },
        merchant?.online_pay && { label: "Recharge", to: "/member/recharge" },
        { label: "My Kids", to: "/member/kids" },        
        { label: 'Logout', onClick: handleLogout }
    ] : [
        { label: "Member Login", to: "/user/signin" },
        { label: "Join us", to: "/user/signup" },
    ];
    const accountmenus = [
        { label: 'Logout', onClick: handleLogout }
    ];
    return <Box sx={{ width: "100%" }}>
        <AppBar position="static" sx={{ ...props.sx, padding: '25px 30px', bgcolor: merchant?.color?.main }}>
            <Container maxWidth="lg" sx={{ padding: 0 }}>
                <Toolbar disableGutters>
                    <IconButton size="large" sx={{ display: { xs: 'block', md: 'none' }, }} onClick={handleOpenNavMenu} color="inherit">
                        {/* <img src="/menu.png" width='20px' /> */}
                        <MenuIcon sx={{color:merchant.color.menu}}/>
                    </IconButton>
                    <MenuDrawer open={anchorElNav} menus={[membermenus]} toggleDrawer={handleToggleDrawer} />
                    <Box sx={{ height: { xs: '40px', md: '60px', }, cursor: 'pointer', ml: { xs: 0, md: 3 }, mr: { xs: 1, md: 1 } }} onClick={() => { window.location.href = merchant?.home }}>
                        <img src={merchant.logo} style={{ height: '100%' }} />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}><Typography sx={{color:"rgb(68, 68, 68)",textTransform:"uppercase",fontWeight:"bold",display:{xs:"block",sm:"none",mb:"none",lg:"block"}}} variant={{xs:"subtitle",lg:"h6"}}>{merchant.title}</Typography></Box>
                    {session && <Box sx={{ flexGrow: 0, display: { md: 'flex', xs: 'none', }, pr: 2 }}>
                        {membermenus.map((item) => (
                            item && item.label !== 'Logout' && <Button
                                key={item.label}
                                onClick={(e) => { item.to ? navigate(item.to) : item.onClick(e) }}
                                sx={{ my: 2, fontSize: '12px', letterSpacing: '1px', display: 'block', width: 'auto', color: merchant?.color.menu, opacity: path[path.length - 1] == item.label.toLowerCase() ? 1 : 0.6 }}>
                                {item.label}
                            </Button>
                        ))}
                        <>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                                    <Avatar sx={{ bgcolor:merchant?.color.second  }} alt={'M'} src="#" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {accountmenus.map((setting) => (
                                    <MenuItem key={setting} onClick={setting.onClick}>
                                        <Typography textAlign="center">{setting.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu></>
                    </Box>}

                </Toolbar>
            </Container>
        </AppBar>
        {/* </AppBar> */}
        <Box sx={{ mt: { xs: 2, md: 2 } }}>
            <Box sx={{ maxWidth: "1200px", ml: { xs: 1, md: "auto" }, mr: { xs: 1, md: "auto" } }}>
                <Grid container spacing={2} >
                    <Grid md={3} xs={12} item>
                        <BalanceView sx={{ display: { xs: "none", md: "block" } }} />
                        <Box sx={{ display: { xs: "block", md: "none" }, p: 2 }}><Outlet /></Box>
                    </Grid>
                    <Grid md={9} xs={12} item>
                        <Box sx={{ display: { xs: "none", md: "block" }, p: 2 }}><Outlet /></Box>
                        <BalanceView sx={{ display: { xs: "block", md: "none" } }} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box sx={{ padding: 2, paddingBottom: 4, paddingTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', background: "#fff" }}>
            <Typography variant="body2" color="text.secondary" align="center">
                {merchant?.title}
                <br></br>
                {'Copyright © '}
                {' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </Box>
    </Box>
}

export default HomeLayout