import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Alert, Container, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import apis from '../api';
import { apiResult } from '../Utils/Common';

export default function Items(props) {
    const [items, setItems] = React.useState()
    const [categories, setCategories] = React.useState()
    const theme = useTheme();
    const [error, setError] = React.useState()
    const hasLoaded = React.useRef(false)
    React.useEffect(() => {
        if (!hasLoaded.current) {
            hasLoaded.current = true
            apis.loadItems().then(ret => {
                apiResult(ret, data => {
                    setCategories(data.category)
                    setItems(data.items)
                }, setError)
            })
        }
    }, [])

    return <Container sx={{ m:0,mt: 2,p:0}}>
        <Box sx={{ display: {md:'flex',xs:""}, alignItems:'baseline', pl: 1.5, pb: 1 }}>
            <Typography variant="h4" component="div">Item Sale</Typography>
            <Typography variant="subtitle" component="div" sx={{ color: 'text.secondary',ml:{xs:0,md:2} }}>Please contact the administrator to purchase.</Typography>
        </Box>
        {error && <Alert variant='error'>{error}</Alert> }
        <Grid container>
            {items && items.map((item, idx) => {
                return <Grid key={idx} xs={12} md={props?.column || 4}>
                    <Card sx={{ display: 'flex',m:1}}>
                        <CardMedia
                            component="img"
                            sx={{ width: {xs:120,md:151},height:{xs:122,md:151} }}
                            image={"/memberapi/home/photo?file="+item.photo}
                            alt="Item photo doesn't exists"
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto',p:{md:"16px",xs:"10px"} }}>
                                <Typography variant="body2" component="div" sx={{ color: 'text.secondary',fontSize:"10px" }}>{item.brand} #{categories && categories[item.category]} </Typography>
                                <Typography component="div" variant={"h6"} >{item.product}</Typography>
                                <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>In stock: {item.remained}</Typography>                                
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', pl: {md:2,xs:"10px"}, pb: {md:2,xs:"10px"} }}>
                                <Typography variant="subtitle2" component="div">${item.price}</Typography>
                                <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary',ml:2 }}>member ${item.member_price}</Typography>
                            </Box>
                        </Box>

                    </Card>
                </Grid>
            })}

        </Grid>
    </Container>
}