
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import { getUserSession, setUserSession } from '../Utils/Common';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import mechantConfig from '../app/mechant.config';

const TopBar = (props) => {
  const [anchorElNav, setAnchorElNav] = useState(null)
  // const [anchorElUser, setAnchorElUser] = useState(null);
  // const navigate = useNavigate()
  // const session = getUserSession()  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleClickMenu = (to) =>{
    window.location.href = to
    handleCloseNavMenu()
  }
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  // const handleLogout = ()=>{
  //   handleCloseUserMenu()
  //   setUserSession()
  //   navigate('/')
  // }  
  const merchant = mechantConfig.getMerchant()
  const menus = merchant?.menus
  // const settings = [
  //   {label:'Logout',onClick:handleLogout}
  // ];
  
  return (
    <AppBar position="static" sx={{...props.sx,padding:'25px 30px',bgcolor:merchant?.color.main}}>
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <Toolbar disableGutters>
          <Box sx={{display: { xs: 'none', md: 'flex' },height: { xs: '40px', md: '60px', } }} onClick={() => { window.location.href = merchant?.home }}>
            <img style={{ mr: 1,maxHeight:'100px' }} src={merchant?.logo} /> 
          </Box>
          <Typography sx={{color:"rgb(68, 68, 68)",textTransform:"uppercase",fontWeight:"bold",display:{xs:"none",mb:"none",lg:"block"}}} variant="h6">{merchant.title}</Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon sx={{color:merchant.color.menu}}/>
            </IconButton>
            <Menu
              id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: 'bottom', horizontal: 'left',}} keepMounted 
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{display: { xs: 'block', md: 'none' },}}>
              {menus.map((menu) => {
                return <MenuItem key={menu.label} onClick={()=>{handleClickMenu(menu.to)}}>
                  <Typography textAlign="center" sx={{fontSize:'12px',fontWeight:500,letterSpacing:'1px',opacity:'0.6'}}>{menu.label}</Typography>
                </MenuItem>
              })}
            </Menu>
          </Box>
          <Box sx={{display: { xs: 'flex', md: 'none' }, }}>
            <img style={{ display: { xs: 'flex', md: 'none' },maxHeight:'60px', mr: 1,ml:1 }} src={merchant?.logo}  />
          </Box>
          {/* <Typography sx={{color:"rgb(68, 68, 68)",textTransform:"uppercase",fontWeight:"bold",display:{xs:"block",sm:"none"}}} variant="h6">{merchant.title}</Typography>
           */}
          <Typography sx={{display:'flex',flex:'1 1 20%'}} ></Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {menus.map((menu) => (
              <Button key={menu.label} onClick={()=>{handleClickMenu(menu.to)}} sx={{ my: 2, color: merchant?.color.menu, display: 'block',fontSize:'12px',opacity:'0.6',fontWeight:500,letterSpacing:'1px' }}>{menu.label}</Button>
            ))}
          </Box>
          
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default TopBar;
