module.exports = {
    axisfencingclub: {
        title: "Axis Fencing Club",
        online_pay:true,
        online_shop:true,
        stripe: {
            stripe_live:"pk_live_51PaI48I9ILgHFhfdrYUIw1zD3H61xE9HAwtTBipNhdjRoZfMIfpBdpCXAyddM8j8KkgsYsOWV4yNChrKtMOUjJCW004HGnXqsJ"
        },
        color:{
            // main:"#000",
            // menu:"#fff",
            main:"#fff",
            menu:"#444",
            second:"rgb(207, 30, 62)",
        },
        // icon:"/icon.png",
        // logo:"/newlogo.png",
        // second_logo:"/newlogo1.png",
        icon:"/axis_new_log.png",
        logo:"/axis_new_log.png",
        second_logo:"/axis_new_log.png",
        home:"https://axisfencingclub.com",
        menus:[            
            {label:"Home",to:"https://axisfencingclub.com/"},
            {label:"ABOUT",to:"https://axisfencingclub.com/about"},
            {label:"PROGRAMS",to:"https://axisfencingclub.com/programs"},
            {label:"COACHES",to:"https://axisfencingclub.com/team"},
            {label:"GALLERY",to:"https://axisfencingclub.com/gallery"},
            {label:"EVENTS",to:"https://axisfencingclub.com/events"},
            {label:"CONTACT",to:"https://axisfencingclub.com/contact"},
            {label:"SHOP",to:"/home/shop"},
            {label:"SIGN IN",to:"/user/signin"},
          ],
        agreement:"/user/terms",
    },
    eaglebladefencersclub: {
        title: "Eagle Blade Fencers Club",
        online_pay:false,  
        online_shop:false,      
        color:{
            main:"#fff",
            menu:"#444",
            second:"#ff0000",
        },
        icon:"/eaglelogo.png",
        logo:"/eaglelogo.png",
        second_logo:"/eaglelogo.png",
        home:"https://www.eaglebladefencersclub.com/",
        menus:[            
            {label:"Home",to:"https://www.eaglebladefencersclub.com/"},
            {label:"ABOUT",to:"https://www.eaglebladefencersclub.com/about-us"}, 
            {label:"CONTACT",to:"https://www.eaglebladefencersclub.com/contact"},            
            {label:"SIGN IN",to:"/user/signin"},
          ],
        agreement:"/FEBC_member_agreement.pdf"
    },
    getMerchantHostName:function() {
        let hh = window.location.hostname.split(".")
        return hh.length>2?hh[1]:hh[0]
    },
    getMerchant:function() {
        let hh = window.location.hostname.split(".")
        hh = hh.length==2?hh[0]:(hh.length==3?hh[1]:hh)
        return this[hh]
    },
}