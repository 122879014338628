import { useEffect, useState, useRef } from "react"
import apis from "../../api"
import { apiResult, getUserSession } from "../../Utils/Common"
import { Box, Grid, Card, Button, CardHeader, Avatar, CardContent, Stack, Alert, Typography, Tabs, Tab } from "@mui/material"
import mechantConfig from "../../app/mechant.config"

const MySchedule = () => {
    const schedules = useRef()
    const [dates, setDates] = useState()
    const [error, setError] = useState()
    const [week, setWeek] = useState(0)
    const weeks = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
    getUserSession(apis)

    const getOneWeekDates = (dd) => {
        let now = dd ? new Date(dd) : new Date()
        let dates = []
        for (let i = 0; i < 7; i++) {
            let y = now.getFullYear()
            let m = now.getMonth() + 1
            let d = now.getDate()
            let w = now.getDay()
            let date = y + "-" + (m < 10 ? ("0" + m) : m) + "-" + (d < 10 ? ("0" + d) : d)
            dates.push({ date: date, wod: w, schedule: [] })
            now.setDate(now.getDate() + 1)
        }
        return dates
    }


    const loadSchedules = (from) => {
        apis.loadSchedule(from ? from : 0).then(ret => {
            apiResult(ret, data => {
                schedules.current = data
                let date = new Date()
                setWeekSchedule(data, date.setDate(date.getDate() + from))
                //setSchedules(data)
            }, setError)
        })
    }
    const setWeekSchedule = (schedules, dd) => {
        let dds = getOneWeekDates(dd)
        let s = {}
        schedules.map(d => {
            for (let i = 0; i < dds.length; i++) {
                if (dds[i].wod == d.wod && d.sdate == dds[i].date) {
                    dds[i].schedule.push(d)
                }
            }
            return true
        })
        setDates(dds)
    }
    useEffect(() => {
        loadSchedules()
    }, [])

    const handleWeek = (w) => {
        setWeek(w)
        loadSchedules(w * 7)
    }

    return <Box sx={{ mt: 2 }}>
        {error && <Alert severity="error" onClose={() => { setError() }}>{error}</Alert>}
        <Grid container spacing={2}>
            {dates && dates.map((item, index) => {
                let curDay = item.date == new Date().toISOString().substring(0, 10)
                return <Grid item key={index} xs={12} sm={6} md={4} >
                    <Card key={index}>
                        <CardHeader sx={{ bgcolor: curDay ? mechantConfig.getMerchant()?.color.second : '#eee', color: curDay ? "#fff" : '#000' }} title={item.date}
                            avatar={<Avatar sx={{ bgcolor: '#fff', color: mechantConfig.getMerchant()?.color.second  }} size={"small"} fontSize={"small"} aria-label="recipe">{weeks[item.wod]}</Avatar>} />
                        <CardContent sx={{ minHeight: 150, padding: '5px !important' }}>
                            {item.schedule.length == 0 && <Box sx={{ p: 1, mt: 1, bgcolor: '#eeee' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ flex: '1 1 100%' }}>no lesson</Typography>
                            </Box>}
                            {item.schedule.map((lesson, index1) => {
                                return <Box key={index1} sx={{ p: 1, mt: 1, bgcolor: '#eee' }}>
                                    <Stack direction={'row'}>
                                        <Typography variant="body2" color="text.secondary" sx={{ flex: '1 1 30%' }}>{lesson?.product.name}</Typography>
                                        <Typography variant="body2" color="text.secondary" >{Math.floor(lesson.begintime / 60) + ":" + (lesson.begintime % 60 < 10 ? ('0' + lesson.begintime % 60) : (lesson.begintime % 60))}~{Math.floor((lesson.begintime + lesson.duration) / 60) + ":" + ((lesson.begintime + lesson.duration) % 60 < 10 ? ('0' + (lesson.begintime + lesson.duration) % 60) : ((lesson.begintime + lesson.duration) % 60))}</Typography>

                                    </Stack>
                                    <Stack direction={'row'}>
                                        <Typography variant="body2" color="text.secondary" sx={{ flex: '1 1 30%' }}>{lesson?.member.name}</Typography>
                                        <Typography variant="body2" color="text.secondary" ></Typography>
                                    </Stack>
                                </Box>
                            })}
                        </CardContent>
                    </Card></Grid>
            })}
        </Grid>

        <Stack direction={"row"} sx={{ mt: 2 }}>
            <Typography component="h6" variant="h6" sx={{ fontWeight: 'bold', flex: '1 1 40%' }}> </Typography>
            {week > 0 && <Button variant="outlined" sx={{ color: '#cf1e3e', borderColor: '#cf1e3e' }} onClick={() => handleWeek((week - 1) >= 0 ? (week - 1) : 0)} >{"Prev"}</Button>}
            <Button variant="outlined" onClick={() => handleWeek(week + 1)} sx={{ ml: 2, color: '#cf1e3e', borderColor: '#cf1e3e' }}>{"Next"}</Button>
        </Stack>
    </Box>
}

export default MySchedule