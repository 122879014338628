import { useEffect, useState } from "react"
import apis from "../../api"
import { apiResult, getUserSession } from "../../Utils/Common"
import { Box, Paper, Stack, Alert, Typography, Button } from "@mui/material"
import { getBalanceProduct } from "../../Utils/balance.config";
import { useNavigate } from "react-router";
import mechantConfig from "../../app/mechant.config";
const BalanceView = (props) => {
    const [balance, setBalance] = useState()
    const [error, setError] = useState()
    const navigate = useNavigate()
    getUserSession(apis)
    useEffect(() => {
        apis.loadBalance().then(ret => {
            apiResult(ret, data => {
                setBalance(data)
            }, setError)
        })
    }, [])
    const handleRecharge = ()=>{
        navigate("/member/recharge")
    }
    const merchant = mechantConfig.getMerchant()
    return (
        <Box sx={{...props.sx}}>{balance && balance.length > 0 ? <Paper sx={{ p: 1, m: 1,mt:2, color: '#000', background: '#eee4' }}>
            <Typography sx={{ flex: '1 1 100%', p: 2 }} component="div" variant="h6" >My balance</Typography>
            <Stack direction={{ xs: "column", sm: 'row' }}>
                {balance.map((b, i) => {
                    return <Box sx={{ flex: '1 1 50%', p: 2 }}>
                        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }} >$ {b.balance}</Typography>
                        <Typography component="div" sx={{ color: '#fff8' }}>{getBalanceProduct(b.type).label}</Typography>
                        {b.balance == 0 && <Alert severity="error" sx={{ mt: 2 }}>Please contact the administrator to recharge as soon as possible</Alert>}
                        {b.balance > 0 && b.balance < 100 && <Alert severity="error" sx={{ mt: 2 }}>Your balance is almost exhausted, please contact the administrator to recharge as soon as possible</Alert>}
                    </Box>
                })}
            </Stack>
        </Paper> : <Paper sx={{ p: 2, m: 2, color: '#fff', background: merchant?.color.second }}>
                <Typography sx={{ flex: '1 1 100%', mb: 1 }} component="div" variant="h6" >My balance</Typography>
                <Stack direction={{ xs: "column", sm: 'row' }}>
                    <Box sx={{ flex: '1 1 50%', p: 2 }}>
                        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }} >$ 0.00</Typography>
                        <Typography component="div" sx={{ color: '#fff8' }}>Private class balance</Typography>
                        <Alert severity="error" sx={{ mt: 2 }}>Please contact the administrator to recharge as soon as possible</Alert>
                    </Box>
                </Stack>
            </Paper>}
            {error && <Alert severity="error">{error}</Alert>}
            {merchant?.online_pay && window.location.pathname != "/member/recharge" && <Stack direction={"column"} sx={{ m: 2 }}>
                <Button variant="contained" color="primary" onClick = {handleRecharge} sx={{ mb: 2 }}>Recharge</Button>                
            </Stack>}
        </Box>
    )
}
export default BalanceView